<template>
  <trang-thai />
</template>
<script>
import TrangThai from '@/modules/danh-muc/components/pages/danh-muc/dung-chung/TrangThai.vue'

export default {
  components: {
    TrangThai,
  },
}
</script>
